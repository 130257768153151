
import {
  computed,
  defineComponent, inject, Ref, ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import useAmount from '@/utilities/composition-functions/amount';
import useCurrecny from '@/utilities/composition-functions/currency';
import { DepositMachineType, DepositTransactionSymbol } from '@/utilities/composition-functions/xstate.machine';
import useTimeService, { ITimeService } from '@/utilities/composition-functions/time';
import { IPrompt, PromptSymbol } from '@/utilities/composition-functions/prompt';
import useKVNDHandler from '@/utilities/composition-functions/kvnd-handler';
import CpsButton from '@/components/utils/CpsButton.vue';
import {TokenStandards} from '@/models';
import CpsGuideModal from '@/components/utils/CpsGuideModal.vue';

interface IGuideContent {
  title: string;
  img: string;
}

function useCountdown(timeService: ITimeService) {
  function startCount(targetTimeSeconds: number) {
    const seconds = !Number.isNaN(+targetTimeSeconds) && targetTimeSeconds > 0 ? targetTimeSeconds : 1;
    const targetTime = timeService.now().add(seconds, 'seconds');
    return timer(0, 1000).pipe(
      takeUntil(timer(seconds * 1000)),
      map(() => timeService.diff(targetTime, timeService.now())),
    );
  }
  return {
    startCount,
  };
}

export default defineComponent({
  name: 'DepositPending',
  components: {
    CpsButton,
    CpsGuideModal,
  },
  setup() {
    const { t } = useI18n();
    const { cryptoCurrencyToKVNDRate, VNDToKVND } = useKVNDHandler();
    const machine = inject<Ref<DepositMachineType>>(DepositTransactionSymbol);
    const machineContext = machine!.value.state.context;
    const { getNameByCurrency } = useCurrecny();
    const { amountFormat} = useAmount();
    const timeService = useTimeService(machineContext.offset);
    const { startCount } = useCountdown(timeService);
    const countdown = ref('');
    const offetTime = ref('');
    const rate = ref(0);
    const toAmount = ref(0);
    const prompt = inject<IPrompt>(PromptSymbol);
    const fromCurrecnyName = computed(() => machine && getNameByCurrency(machineContext.fromCurrency));
    const toCurrencyName = computed(() => machine && getNameByCurrency(machineContext.toCurrency));
    const isShowKVND = computed(() => machineContext.isShowKVND);
    const isShowGuideModal = ref<boolean>(false);
    const guideContent: Ref<IGuideContent[]> = ref([
      {
        title: t('Deposit_Detail_Guide_Tips1'),
        img: 'deposit-detail-guide-1.png',
      },
      {
        title: t('Deposit_Detail_Guide_Tips2'),
        img: 'deposit-detail-guide-2.png',
      },
    ]);

    async function init() {
      if (machineContext.isShowKVND) {
        if (machineContext.rate) {
          rate.value = cryptoCurrencyToKVNDRate(machineContext.rate);
        }
        if (machineContext.toAmount) {
          toAmount.value = VNDToKVND(machineContext.toAmount);
        }
      } else {
        toAmount.value = machineContext.toAmount as number;
        rate.value = machineContext.rate as number;
      }
      offetTime.value = timeService.getSystemFormat(timeService.getMomentByDate(machineContext.rateDate));
      const count = timeService.diff(timeService.addTime(timeService.getMomentByDate(machineContext.rateDate), 5, 'minutes'), timeService.now());
      startCount(count).subscribe((s) => {
        countdown.value = timeService.format(timeService.getMomentBySecond(s), 'mm:ss');
      });
    }
    function onSubmit() {
      machine!.value.send('Next');
    }
    function onQrCode() {
      machine!.value.send({ type: 'QrCode'});
    }
    return {
      t,
      machineContext,
      init,
      machine,
      rate,
      toAmount,
      countdown,
      timeService,
      offetTime,
      fromCurrecnyName,
      toCurrencyName,
      prompt,
      onSubmit,
      amountFormat,
      onQrCode,
      TokenStandards,
      isShowGuideModal,
      guideContent,
      isShowKVND,
    };
  },
  created() {
    this.init();
  },
});
