/* eslint-disable */
import { apiErrorSubject, StatusCode } from '@higgs/utils';
import { filter } from 'rxjs/operators';
import { Subject } from 'rxjs';

interface IApiError {
  status: number;
  message: string;
}

interface IErrorHandlerConfig {
  badRequestHandler: (app: any, errorSubs: IApiError) => Promise<void>;
  catchedErrorHandler: (app: any, errorSubs: IApiError) => Promise<void>;
  unauthorizedHandler: (app: any, errorSubs: IApiError) => Promise<void>;
  validationErrorHandler: (app: any, errorSubs: IApiError) => Promise<void>;
  unknowErrorHandler: (app: any, errorSubs: IApiError) => Promise<void>;
  inertnalErrorHandler: (app: any, errorSubs: IApiError) => Promise<void>;
  baseRx: (app: any, errorSubs: Subject<IApiError>) => Promise<void>;
  baseError: (app: any, errorSubs: IApiError) => Promise<void>;
  baseSuccess: (app: any, errorSubs: IApiError) => Promise<void>;
}

export default {
  statusInError: {} as any,
  install(app: any, config: IErrorHandlerConfig) {
    if (!config) {
      this.baseRx(app, apiErrorSubject);
      return;
    }
    const {
      baseRx,
      baseError,
      baseSuccess,
      catchedErrorHandler,
      unauthorizedHandler,
    } = config;

    baseRx && (this.baseRx = baseRx);
    baseError && (this.baseError = baseError);
    baseSuccess && (this.baseSuccess = baseSuccess);
    // badRequestHandler && (this.baseError = baseError);
    catchedErrorHandler && (this.catchedErrorHandler = catchedErrorHandler);
    // inertnalErrorHandler && (this.baseError = baseError);
    // validationErrorHandler && (this.baseError = baseError);
    unauthorizedHandler && (this.unauthorizedHandler = unauthorizedHandler);
    // unknowErrorHandler && (this.baseError = baseError);
    this.baseRx(app, apiErrorSubject);
  },
  baseRx(app: any, errorSubs: Subject<IApiError>) {
    const baseSuccess = errorSubs.pipe(
      filter(
        s =>
          !this.statusInError[StatusCode[s.status]] &&
          s.status === StatusCode.Success,
      ),
    );
    const baseError = errorSubs.pipe(
      filter(
        s =>
          s.status !== StatusCode.Success &&
          s.status !== StatusCode.CatchedError &&
          !this.statusInError[StatusCode[s.status]],
      ),
    );

    baseSuccess.subscribe(s => this.baseSuccess(app, s));
    baseError.subscribe(s => this.baseError(app, s));

    // const badRequest = errorSubs.pipe(filter(s => !this.statusInError[StatusCode[s.status]] && s.status === StatusCode.BadRequest));
    const unauthorized = errorSubs.pipe(
      filter(s => s.status === StatusCode.Unauthorized),
    );
    // const inertnalError = errorSubs.pipe(filter(s => !this.statusInError[StatusCode[s.status]] && s.status === StatusCode.InertnalError));
    // const validationError = errorSubs.pipe(filter(s => !this.statusInError[StatusCode[s.status]] && s.status === StatusCode.ValidationError));
    const catchedError = errorSubs.pipe(
      filter(
        s =>
          !this.statusInError[StatusCode[s.status]] &&
          s.status === StatusCode.CatchedError,
      ),
    );
    // const unknowError = errorSubs.pipe(filter(s => !this.statusInError[StatusCode[s.status]] && s.status === StatusCode.UnknowHttpError));
    const serviceUnavalible = errorSubs.pipe(
      filter(s => s.status === StatusCode.ServiceUnavailable),
    );

    unauthorized.subscribe(s => this.unauthorizedHandler(app, s));
    // badRequest.subscribe(s => this.baseError(app, s));
    // inertnalError.subscribe(s => this.baseError(app, s));
    // validationError.subscribe(s => this.baseError(app, s));
    catchedError.subscribe(s => this.catchedErrorHandler(app, s));
    // unknowError.subscribe((s) => this.baseError(app, s));
    serviceUnavalible.subscribe(s => this.baseError(app, s));
  },

  catchedErrorHandler(app: any, s: IApiError) {
    this.baseError(app, s);
  },
  unauthorizedHandler(app: any, s: IApiError) {
    window.location.href = `${window.location.origin}/logout`;
  },
  async baseSuccess(app: any, s: IApiError) {
    if (app.config.globalProperties.$message) {
      await app.config.globalProperties.$message.success(s.message);
    } else {
      alert(s.message);
    }
  },
  async baseError(app: any, s: IApiError) {
    this.statusInError[StatusCode[s.status]] = true;
    if (app.config.globalProperties.$modal) {
      await app.config.globalProperties.$modal.error({title: 'Oops!', content: JSON.stringify(s.message)});
    } else {
      alert(JSON.stringify(s.message));
    }
    this.statusInError[StatusCode[s.status]] = false;
  },
};
