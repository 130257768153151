<template>
  <div class="container mt-24">
    <div class="success" />
    <h1 class="text-3xl text-center font-bold mt-9 text-black-main">{{t("Deposit_Submit_Success")}}</h1>
    <div class="mt-4 text-center font-medium text-xs text-portal-darkgrey">
      <p>{{t("Deposit_Submit_Success_Description1")}}</p>
      <p>{{t("Deposit_Submit_Success_Description2")}}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Success',
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  created() {
    sessionStorage.setItem('success', 'success');
  },
});
</script>

<style lang="css" scoped>
.success {
  background-image: url('~@/assets/svg/submit-success.svg');
  width: 105px;
  height: 103px;
  margin: auto;
  position: relative;
  background-repeat: no-repeat;
}
/* .result-page-container {
  margin-top: 100px;
  h1 {
  margin-top: 45px;
  }
  button {
    width: 170px;
    margin: auto;
    margin-top: 35px;
  }
  p:first-of-type {
    margin-top: 25px;
  }
}
.wallet-not-available {
  background-image: url('~@/assets/svg/WalletError.svg');
  width: 105px;
  height: 100px;
  margin: auto;
  position: relative;
  background-repeat: no-repeat;
} */
</style>
