<style lang="scss">
.the-radio-button {
  display: inline-block;
  color: #505050;
  cursor: pointer;
  &[data-is-checked='true'] {
    .the-radio-button__circle {
      &:before {
        background-color: rgba($color: #ffd530, $alpha: 1);
      }
    }
  }
  &__circle {
    position: relative;
    display: inline-block;
    border: 1px solid #989898;
    width: 16px;
    height: 16px;
    border-radius: 10px;
    margin-right: 6px;
    vertical-align: middle;

    &:before {
      content: '';
      position: absolute;
      display: inline-block;
      width: 80%;
      height: 80%;
      background-color: rgba($color: #000000, $alpha: 0);
      border-radius: 10px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &__text {
    display: inline-block;
    vertical-align: middle;
  }
}
</style>

<template>
  <div
    class="the-radio-button"
    @click="handleClick(item[trackById])"
    :data-is-checked="fullValue === item[trackById]"
  >
    <span class="the-radio-button__circle"></span>
    <span class="the-radio-button__text">{{ item[trackByName] }}</span>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'TheRadioButton',
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    item: {
      type: Object,
      default: () => ({
        id: 0,
        name: 'not set item',
      }),
    },
    trackById: {
      type: String,
      default: 'id',
    },
    trackByName: {
      type: String,
      default: 'name',
    },
  },
  emits: ['update:modelValue'],
  components: {},
  setup(props, { emit }) {
    const fullValue = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });
    function handleClick(value: any) {
      fullValue.value = value;
    }
    return {
      fullValue,
      handleClick,
    };
  },
});
</script>
