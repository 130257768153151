<template>
  <div class="container mt-24">
    <div class="error" />
    <h1 class="text-3xl text-center font-bold mt-9 text-black-main">{{ errorCode }}</h1>
    <div class="mt-4 text-center font-medium text-xs text-portal-darkgrey">
      <p class="text-center text-sm description">{{t("Deposit_Error_Description")}}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { DepositTransactionSymbol } from '@/utilities/composition-functions/xstate.machine';
import { defineComponent, inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ErrorPage',
  setup() {
    const { t } = useI18n();
    const machine = inject(DepositTransactionSymbol) as any;
    // machine service 開始前的錯誤都預設顯示 501
    const errorCode = ref('501');
    if (machine.value) {
      const machineContext = machine.value.state.context;
      errorCode.value = machineContext.errorCode;
    }

    return {
      t,
      errorCode,
    };
  },
});
</script>

<style lang="css" scoped>
.error {
  background-image: url('~@/assets/svg/fail.svg');
  width: 105px;
  height: 100px;
  margin: auto;
  position: relative;
  background-repeat: no-repeat;
}
</style>
