<template>
  <div class="container max-w-lg min-h-screen py-12 px-6 m-auto bg-white relative">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseLayout',
});
</script>
