<template>
  <div class="prompt__wrapper" v-if="isShow">
    <div class="prompt__item">
      <slot>
        <img :src="require(`@/assets/svg/${iconName}`)"  />
        <div class="prompt__text">
          <div class="text__title" v-if="title">
            {{ title }}
          </div>
          <div class="text__content" v-if="content">
            {{ content }}
          </div>
        </div>
      </slot>
      <div class="close_btn" @click.stop="onClick">
        <i class="pp-icon-cross-circle-big close-icon" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PromptIcon } from '@/utilities/composition-functions/prompt';
import { timer } from 'rxjs';
import {
  computed, defineComponent, ref, watchEffect,
} from 'vue';

export default defineComponent({
  name: 'CpsPrompt',
  setup(props) {
    const isShow = ref(false);
    const iconName = computed(() => {
      switch (props.icon) {
        case PromptIcon.success:
          return 'Success.svg';
        default:
          return 'Info.svg';
      }
    });
    return {
      isShow,
      iconName,
    };
  },
  created() {
    watchEffect(() => {
      if (this.value) {
        this.isShow = true;
        timer(this.durations).subscribe(() => {
          this.isShow = false;
          this.$emit('update:value', false);
        });
      }
    });
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    durations: {
      type: Number,
      default: 2000,
    },
    value: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Number,
      default: PromptIcon.info,
    },
  },
});
</script>

<style lang="scss" scoped>
.prompt__wrapper {
  position: fixed;
  padding: 10px 10px 28px 10px;
  top: 0;
  left: 0;
  width: 100%;
  display: -webkit-flex; /* Safari */
  -webkit-flex-direction: column-reverse; /* Safari 6.1+ */
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  z-index: 3002;
  background-image: linear-gradient(to top, rgba(100, 100, 100, 0), rgba(0, 0, 0, 0.5));
}

.prompt__item {
  position: relative;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: row;
  img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
  .prompt__icon {
    width: 36px;
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }
  .prompt__text {
    .text__title {
      color: #292d33;
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 4px;
      word-wrap: break-word;
    }
    .text__content {
      font-size: 14px;
      color: #52575f;
      word-wrap: break-word;
    }
  }
  .prompt_status {
    color: rgb(245, 166, 35);
  }
  &+.prompt__item {
    margin-bottom: 8px;
  }
  .close_btn {
    position: absolute;
    width: 20px;
    height: 20px;
    color: white;
    border-radius: 50%;
    top: 10px;
    right: 10px;
    .close-icon {
      color: #808080;
      font-size: 20px;
    }
  }
}
</style>
