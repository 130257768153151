import { AdjustNumber } from '@higgs/utils';
import Decimal from 'decimal.js';

interface IMetricMappingList {
  value: number;
  symbol: string;
}

// 小數點計算皆為無條件捨去
// 因為要迴避掉科學記號, 使用decimal.js的toFixed做處理(小於1且小數點後帶有6個以上的0 or 整數位數21位以上 js會自動將數字轉換為科學記號)
export default function useAmount() {
  // 取小數點後幾位
  function formatDecimal(amount: number, precision: number): string {
    if (amount >= 0) {
      return new Decimal(parseFloat(AdjustNumber.floor(amount, precision).toFixed(precision))).toFixed();
    }
    return new Decimal(parseFloat(AdjustNumber.ceil(amount, precision).toFixed(precision))).toFixed();
  }
  // 取小數點後幾位+顯示千分位
  function amountFormat(decimalPoint: number, sign: any = '') {
    return (v: any) => {
      if (!Number.isNaN(+v) && v !== null) {
        const format = decimalPoint || 2;
        const numberSign = +v >= 0 ? '' : '-';
        // 保留原有作法是為了要清除小數點後無效的0
        const [round, ...digit] = new Decimal(parseFloat(AdjustNumber.floor(Math.abs(v), format).toFixed(format))).toFixed().split('.');
        return `${numberSign}${sign || ''}${[round.replace(/\B(?=(\d{3})+(?!\d))/g, ','), ...digit].join('.')}`;
      }
      return v;
    };
  }
  // 顯示國際單位制
  function metricNumberFormat(num: number): string {
    const metricMappingList = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'K' },
      // 如果確認需求需要再打開
      // { value: 1e6, symbol: 'M' },
      // { value: 1e9, symbol: 'G' },
      // { value: 1e12, symbol: 'T' },
      // { value: 1e15, symbol: 'P' },
      // { value: 1e18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = metricMappingList.slice().reverse().find((list: IMetricMappingList) => num >= list.value);
    if (item) {
      const [round, ...digit] = new Decimal(num).div(item.value).toFixed().split('.');
      return [round.replace(/\B(?=(\d{3})+(?!\d))/g, ','), ...digit].join('.').replace(rx, '$1') + item.symbol;
      // 如果需求需要顯示到K以上的話 可以移除掉顯示千分位邏輯
      // return new Decimal(num).div(item.value).toString().replace(rx, '$1') + item.symbol;
    }
    return '0';
  }

  return {
    amountFormat,
    metricNumberFormat,
    formatDecimal,
  };
}
