<template>
  <BaseLayout>
    <CpsPrompt :title="prompt.title" :content="prompt.content" :icon="prompt.icon" v-model:value="prompt.isShow" />
    <component :is="stateComponent" />
  </BaseLayout>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import {
  defineComponent, provide, reactive, ref,
} from 'vue';
import {
  interpret, StateValueMap,
} from 'xstate';
import BaseLayout from '@/components/layout/BaseLayout.vue';
import DepositNew from '@/views/deposit/DepositNew.vue';
import DepositPending from '@/views/deposit/DepositPending.vue';
import DepositHashId from '@/views/deposit/DepositHashId.vue';
import CpsPrompt from '@/components/utils/CpsPrompt.vue';
import WalletNotAvailable from '@/views/WalletNotAvailable.vue';
import Success from '@/views/Success.vue';
import ErrorPage from '@/views/ErrorPage.vue';
import QrCode from '@/views/QrCode.vue';
import {
  DepositMachineType,
  DepositTransactionSymbol,
  TRANSACTION_STATES,
  useDepositTransactionMachine,
} from '@/utilities/composition-functions/xstate.machine';
import usePrompt, { PromptSymbol } from '@/utilities/composition-functions/prompt';
import { initHandler } from '@/api/init';
import { transactionHandler } from '@/api/transaction';
import { TransactionType } from './models';

export default defineComponent({
  components: {
    BaseLayout,
    DepositNew,
    DepositPending,
    DepositHashId,
    CpsPrompt,
    WalletNotAvailable,
    Success,
    ErrorPage,
    QrCode,
  },
  setup() {
    const { t, locale } = useI18n();
    const stateComponent = ref('');
    const { getMachine } = useDepositTransactionMachine();
    const prompt = reactive(usePrompt());
    const machine = ref<DepositMachineType | null>(null);
    provide(DepositTransactionSymbol, machine);
    provide(PromptSymbol, prompt);
    async function init() {
      try {
        locale.value = await initHandler.getCultureCodeAsync();
        const data = await transactionHandler.getDepositAsync();
        const service = interpret(getMachine(TransactionType.Deposit, data));
        service.start();
        machine.value = service;
        machine.value.subscribe((state: any) => {
          if (typeof state.value === 'object') {
            const parentState = Object.keys(state.value)[0];
            const stateValueMap = state.value as StateValueMap;
            const stateValue = stateValueMap[parentState];
            if (stateValue === TRANSACTION_STATES.QRCODE) {
              stateComponent.value = stateValue;
              return;
            }
            stateComponent.value = `${parentState}${stateValue}`;
          } else if (state.value === TRANSACTION_STATES.RESULT) {
            machine!.value!.send('Next');
          } else {
            stateComponent.value = state.value;
          }
        });
        machine.value.send('Next');
      } catch (_err) {
        // machine service 開始前, API 如果有任何錯誤就會顯示 ErrorPage
        stateComponent.value = TRANSACTION_STATES.ERROR;
      }
    }
    return {
      t,
      locale,
      machine,
      stateComponent,
      init,
      prompt,
    };
  },
  created() {
    this.init();
  },
});
</script>

<style lang="scss">
@import '@/scss/main.scss';
</style>
