
import { defineComponent, inject, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import CpsButton from '@/components/utils/CpsButton.vue';
import { DepositMachineType, DepositTransactionSymbol } from '@/utilities/composition-functions/xstate.machine';

export default defineComponent({
  name: 'WalletNotAvailable',
  components: {
    CpsButton,
  },
  setup() {
    const { t } = useI18n();
    const machine = inject<Ref<DepositMachineType>>(DepositTransactionSymbol);
    function restart() {
      machine!.value.send('Next');
    }
    return {
      t,
      restart,
    };
  },
});
