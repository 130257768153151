
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'TheRadioButton',
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    item: {
      type: Object,
      default: () => ({
        id: 0,
        name: 'not set item',
      }),
    },
    trackById: {
      type: String,
      default: 'id',
    },
    trackByName: {
      type: String,
      default: 'name',
    },
  },
  emits: ['update:modelValue'],
  components: {},
  setup(props, { emit }) {
    const fullValue = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });
    function handleClick(value: any) {
      fullValue.value = value;
    }
    return {
      fullValue,
      handleClick,
    };
  },
});
