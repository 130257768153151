<template>
  <input
    class="flex flex-1 w-0 text-right mr-6 text-portal-darkgrey"
    :class="{ readonly: readonly, focus: isFocus }"
    :readonly="readonly"
    :placeholder="placeholder"
    :maxlength="maxLength"
    v-model="bindValue"
    @input="insert"
    @focus="onFocus"
    @blur="onBlur"
  />
</template>

<script lang="ts">
import {
  computed,
  defineComponent, inject, PropType, Ref, ref, watch,
} from 'vue';
import { ValidatorFn } from '@higgs/utils/lib/directives/validators';
import useFormControl from '@/utilities/composition-functions/form.control';
import { FormGroup } from '@higgs/utils';

function useNumberFormat(props: any, context: any, inputValue: Ref<string>, oldValue: Ref<string>) {
  const bindValue = computed({
    get: () => {
      let value = '';
      if (props.useComma) {
        const [round, ...digit] = inputValue.value.split('.');
        value = `${[round.replace(/\B(?=(\d{3})+(?!\d))/g, ','), ...digit].join('.')}`;
      } else {
        // eslint-disable-next-line prefer-destructuring
        value = inputValue.value;
      }
      return value;
    },
    set: (value) => {
      inputValue.value = value;
    },
  });

  function setValue(v: any) {
    let val = v === null ? '' : `${v}`;
    if (Number.isNaN(+val)) {
      val = '';
    } else if (val.indexOf('.') > 0) {
      const [round, ...digit] = val.split('.');
      if (digit[0].length > props.precision) {
        digit[0] = digit[0].substring(0, props.precision);
      }
      val = [round, digit].join('.');
    }
    inputValue.value = val;
    oldValue.value = val;
  }

  function transformHalf(v: any) {
    let temp = '';
    if (props.isOnlyHalfWidth) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < v.length; i++) {
        let charCode = v.charCodeAt(i);
        if (charCode >= 65281 && charCode <= 65374) {
          charCode -= 65248;
        } else if (+charCode === 12288) {
          // 全形空白轉半形
          charCode = 32;
        }
        temp += String.fromCharCode(charCode);
      }
    } else {
      temp = v;
    }
    return temp;
  }

  function insert() {
    let val = transformHalf(inputValue.value);
    if (props.pureNumber) {
      if (val.match(/^\d*$/) === null) {
        val = oldValue.value;
      }
    } else {
      val = val.replace(/,/g, '');
      if (val === '') {
        inputValue.value = '';
        oldValue.value = '';
        context.emit('update:value', null);
        return;
      }

      if (props.precision === 0 && val.indexOf('.') >= 0) {
        val = oldValue.value;
      } else if (val === '.') {
        val = `0${val}`;
      } else if (val !== '' && val.indexOf('.') > 0 && val.split('.')[1].length > props.precision) {
        val = oldValue.value;
      }

      if (+val > +props.max) {
        val = `${props.max}`;
      } else if (props.max === null && +val > 9999999) {
        val = '9999999';
      } else if (props.min !== null && +val < +props.min) {
        val = `${props.min}`;
      }
    }

    if (!Number.isNaN(+val)) {
      inputValue.value = val;
      oldValue.value = val;
      if (props.returnNumber) {
        context.emit('update:value', +val);
      } else {
        context.emit('update:value', val);
      }
    } else if (inputValue.value === oldValue.value) {
      inputValue.value = '';
      oldValue.value = '';
    } else {
      inputValue.value = oldValue.value;
    }
  }
  return {
    bindValue,
    setValue,
    insert,
  };
}

export default defineComponent({
  name: 'CpsNumberInput',
  setup(props, context) {
    const isFocus = ref(false);
    const inputValue = ref('');
    const oldValue = ref('');
    const formGroup: FormGroup | undefined = inject('formGroup');
    const formControlModule = useFormControl(props, context, formGroup);
    const { setValue, bindValue, insert } = useNumberFormat(props, context, inputValue, oldValue);

    function updateValue(value: any) {
      setValue(value);
      if (formControlModule.formControl) {
        formControlModule.formControl.setValue(value);
        if (formControlModule.formControl.errors) {
          context.emit('error', formControlModule.formControl.errors);
        }
      }
    }
    watch(() => props.value, (value) => updateValue(value));
    function onFocus(event: Event) {
      if (props.readonly) {
        return;
      }
      isFocus.value = true;
      context.emit('update:focus', event);
    }

    function onBlur(event: Event) {
      if (props.readonly) {
        return;
      }
      isFocus.value = false;
      formControlModule.formControl.markAsTouched();
      context.emit('update:blur', event);
    }

    return {
      onFocus,
      onBlur,
      isFocus,
      bindValue,
      insert,
      updateValue,
      formInit: formControlModule.formInit,
    };
  },
  created() {
    this.formInit();
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    value: {
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    apiErrorMessage: {
      type: String,
      default: '',
    },
    maxLength: {
      type: Number,
      default: 50,
    },
    validators: {
      type: Array as PropType<ValidatorFn[]>,
      default: () => [],
    },
    precision: {
      type: Number,
      default: 4,
    },
    useComma: {
      type: Boolean,
      default: true,
    },
    returnNumber: {
      type: Boolean,
      default: true,
    },
    pureNumber: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: 9999999,
    },
    min: {
      type: Number,
      default: -9999999,
    },
    isOnlyHalfWidth: {
      type: Boolean,
      default: true,
    },
  },
  // components: {
  //   DepositNew,
  // },
});
</script>
