import Decimal from 'decimal.js';

export default function useKVNDHandler() {
  function cryptoCurrencyToKVNDRate(rate: number): number {
    const kvndRate = new Decimal(rate).div(1000).toFixed(6, Decimal.ROUND_DOWN);
    return new Decimal(kvndRate).toNumber();
  }

  function VNDToKVND(toAmount: number | string, round: typeof Decimal.ROUND_DOWN | typeof Decimal.ROUND_UP = Decimal.ROUND_DOWN): number {
    const kvnd = new Decimal(toAmount).div(1000).toFixed(2, round);
    return new Decimal(kvnd).toNumber();
  }

  return {
    cryptoCurrencyToKVNDRate,
    VNDToKVND,
  };
}
