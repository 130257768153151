
import { from } from 'rxjs';
import {
  defineComponent, inject, provide, Ref, ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import CpsTextarea from '@/components/utils/CpsTextarea.vue';
import CpsButton from '@/components/utils/CpsButton.vue';
import { FormGroup, Validators } from '@higgs/utils';
import TransactionHandler from '@/api/transaction';
import { UpdateDepositRequest } from '@/models/api/transaction';
import { DepositMachineType, DepositTransactionSymbol } from '@/utilities/composition-functions/xstate.machine';

export default defineComponent({
  name: 'DepositHashId',
  components: {
    CpsTextarea,
    CpsButton,
  },
  setup() {
    const { t } = useI18n();
    const machine = inject<Ref<DepositMachineType>>(DepositTransactionSymbol);
    const hashId = ref<string>('');
    const checkboxValidators = [Validators.requiredTrue];
    const inputPattern = [Validators.pattern(/^[A-Za-z0-9]+$/, 'characterRange')];
    const formGroup = new FormGroup({});
    const isValid = ref(false);
    const textareaValidatorMessageMap = {
      pattern: {
        characterRange: 'Deposit_Textarea_Wrong_Format_Warning_Default_Content',
      },
    };
    formGroup.statusChanges.subscribe((x) => isValid.value = (x === 'VALID'));
    provide('formGroup', formGroup);
    function submit() {
      const request: UpdateDepositRequest = {
        ticketId: machine!.value.state.context.ticketId,
        hashId: hashId.value,
      };
      from(TransactionHandler.updateDepositAsync(request)).subscribe({
        next: (result) => machine!.value.send('Next', { payload: result}),
        error: (statusCode: string) => machine!.value.send('Error', { errorCode: statusCode }),
      });
    }
    function cancel() {
      machine!.value.send({type: 'Cancel'});
    }
    return {
      t,
      machine,
      submit,
      cancel,
      isValid,
      hashId,
      checkboxValidators,
      inputPattern,
      textareaValidatorMessageMap,
    };
  },
});
