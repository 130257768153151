import { reactive, Ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

export enum PromptIcon {
  info,
  success,
}
export interface IPrompt {
  title: Ref<string>;
  content: Ref<string>;
  isShow: Ref<boolean>;
  copySuccess: () => void;
  setNewPrompt: (newTitle: string, newcContent: string) => void;
}

export default function usePrompt() {
  const { t } = useI18n();
  const promptData = reactive({
    title: '',
    content: '',
    isShow: false,
    icon: PromptIcon.info,
  });
  function setNewPrompt(newTitle: string, newcContent: string, icon: PromptIcon = PromptIcon.info) {
    promptData.title = newTitle;
    promptData.content = newcContent;
    promptData.isShow = true;
    promptData.icon = icon;
  }
  function copySuccess() {
    setNewPrompt('', t('Deposit_Copy_Success'), PromptIcon.success);
  }
  return {
    copySuccess, setNewPrompt, ...toRefs(promptData),
  };
}
export const PromptSymbol = Symbol('PromptSymbol');
