export enum TransactionType {
  None = 1,
  Deposit = 2,
  Withdrawal = 3
}

export interface IEnumToArray {
  id: number;
  name: string | number;
}

function EnumToArray(o: any): IEnumToArray[] {
  return Object.entries(o)
    .filter((x) => typeof x[1] === 'number')
    .map((value) => ({ id: value[1] as number, name: value[0] }));
}

export enum Currency {
  CNY = 1,
  VND = 2,
  THB = 3,
  USD = 4,
  ETH = 1001,
  USDT = 1002,
  USDC = 1003,
}

export enum TokenStandards {
  ERC20 = 1,
  TRC20 = 2,
}

export enum DepositStatus {
  None = 0,
  Float = 10,
  Processing = 20,
  PendingHold = 30,
  Approved = 41,
  Rejected = 42,
}

export const TokenStandardsList = EnumToArray(TokenStandards);
