<template>
  <div class="my-2.5">
    <textarea
      class="resize-none rounded-md border border-solid border-text-area text-black-secondary p-3 w-full h-52"
      :class="{'invalid': isInvalid }"
      :readonly="readonly"
      :placeholder="placeholder"
      :maxlength="maxLength"
      v-model="bindValue"
      @input="onInputChange"
      @focus="onFocus"
      @blur="onBlur"
    />
    <span class="input__warning text-xs" v-if="isErrorShowMessage && error">{{ t(error) }}</span>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import {
  defineComponent, inject, PropType, ref,
} from 'vue';
import { ValidatorFn } from '@higgs/utils/lib/directives/validators';
import useFormControl from '@/utilities/composition-functions/form.control';
import { FormGroup } from '@higgs/utils';

function useInputGuardian(reg: RegExp | string, trimInput: boolean, initValue: any = '') {
  let bindValue = initValue;
  let oldValue = initValue;

  function checkCharacterValid(value: any) {
    if (typeof (reg) === 'string') {
      return new RegExp(reg).test(value);
    }
    return reg.test(value);
  }

  function onInputChange(callback: any) {
    return (event: any) => {
      const { value } = event.target;
      if (!checkCharacterValid(value) && value !== '' && value !== null) {
        callback(oldValue);
      } else {
        oldValue = event.target.value;
        bindValue = trimInput ? event.target.value.trim() : event.target.value;
        callback(bindValue);
      }
    };
  }

  return {
    onInputChange,
  };
}

export default defineComponent({
  name: 'CpsTextarea',
  setup(props, context) {
    const { t } = useI18n();
    const isFocus = ref(false);
    const formGroup: FormGroup | undefined = inject('formGroup');
    const formControlModule = useFormControl(props, context, formGroup);
    const { onInputChange } = useInputGuardian(props.pattern, props.trimInput);
    const bindValue = ref('');
    const isErrorShowMessage = ref<boolean>(false);
    const error = ref<any>(null);
    const isInvalid = ref<boolean>(false);

    formGroup!.statusChanges.subscribe((x) => isErrorShowMessage.value = x === 'INVALID');
    function onFocus(event: Event) {
      if (props.readonly) {
        return;
      }
      isFocus.value = true;
      context.emit('update:focus', event);
    }

    function getErrorMessage(errors: any[]) {
      if (errors) {
        const key = Object.keys(errors)[0];
        if (props.validatorMessageMap && key !== 'pattern') {
          const message = props.validatorMessageMap[key];
          if (message) {
            return message;
          }
        }
        switch (key) {
          case 'pattern':
            return props.validatorMessageMap[key][errors[key as any].name];
          case 'required':
            return 'Common_Required';
          default:
            return 'Common_Wrong_Format_Warning_Default_Content';
        }
      }
      return '';
    }

    function getFormControlError(formControlError: any) {
      if (formControlError) {
        error.value = getErrorMessage(formControlError as any);
        isInvalid.value = true;
        return;
      }
      isInvalid.value = false;
    }

    function inputChangeCallback(value: any) {
      bindValue.value = value;
      context.emit('update:value', value);
      formControlModule.formControl.setValue(value);
      getFormControlError(formControlModule.formControl.errors);
    }

    function onBlur(event: any) {
      if (props.readonly) {
        return;
      }
      isFocus.value = false;
      formControlModule.formControl.markAsTouched();
      getFormControlError(formControlModule.formControl.errors);
      context.emit('update:blur', event);
    }

    return {
      t,
      onFocus,
      onBlur,
      bindValue,
      isFocus,
      onInputChange: onInputChange(inputChangeCallback),
      formInit: formControlModule.formInit,
      isErrorShowMessage,
      getErrorMessage,
      error,
      isInvalid,
    };
  },
  created() {
    this.formInit();
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    value: {
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    apiErrorMessage: {
      type: String,
      default: '',
    },
    maxLength: {
      type: Number,
      default: 100,
    },
    validators: {
      type: Array as PropType<ValidatorFn[]>,
      default: () => [],
    },
    pattern: {
      type: String,
      default: '',
    },
    trimInput: {
      type: Boolean,
      default: false,
    },
    validatorMessageMap: {
      type: Object,
      default: () => ({}),
    },
  },
});
</script>
<style scoped lang="css">
.border-text-area {
  border-color: #c4c4c4;
}
.border-text-area.invalid {
  border-color: #E93F3F;
}
.input__warning {
  color: #E93F3F;
  white-space: pre-wrap;
}
</style>
