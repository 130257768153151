import { FormControl, FormGroup, Validators } from '@higgs/utils';
import { ValidatorFn } from '@higgs/utils/lib/directives/validators';
import { computed } from 'vue';

export default function useFormControl(props: any, context: any, formGroup: FormGroup | undefined) {
  const formControl = new FormControl(props.value, props.validators!);
  const bindValue = computed({
    get: () => props.value,
    set: (value) => {
      context.emit('update:value', value);
      formControl.setValue(value);
    },
  });

  function formInit() {
    let validators: ValidatorFn[] = [];
    if (props.required) {
      validators.push(Validators.required);
    }
    if (props.validators.length > 0) {
      validators = validators.concat(props.validators);
    }
    formControl.setValidators(validators);
    formControl.updateValueAndValidity();
    formControl.statusChanges.subscribe((x: string) => (x === 'VALID' ? context.emit('update:valid') : context.emit('update:inValid')));
    if (formGroup) {
      formGroup.addControl(props.name, formControl);
    }
  }

  return {
    bindValue,
    formControl,
    formInit,
  };
}
