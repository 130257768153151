import { HttpHandler, Interceptor } from '@/utilities/http.handler';

class InitHandler {
  handler: HttpHandler;

  constructor() {
    this.handler = new HttpHandler('/api/init');
  }

  @Interceptor()
  getCultureCodeAsync(): Promise<string> {
    return this.handler.get<string>('culture');
  }
}

export const initHandler = new InitHandler();

export default initHandler;
