<template>
  <button
    class="block h-12 text-sm font-medium rounded-md focus:outline-none"
    :class="classNames"
    :disabled="disabled"><slot />
  </button>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
} from 'vue';

export default defineComponent({
  name: 'CpsButton',
  setup(props) {
    const classNames = computed(() => {
      if (props.disabled) {
        return 'bg-disabled text-white';
      }
      switch (props.type) {
        case 'cancel':
          return 'bg-black-secondary text-white';
        default:
          return 'bg-button text-black-secondary';
      }
    });
    return {
      classNames,
    };
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
<style scoped>
.bg-disabled {
  background-color: #dadada;
}
</style>
