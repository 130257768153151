import { RxSignalR } from '@higgs/utils';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

export enum HubConnectionState {
  /** The hub connection is disconnected. */
  Disconnected = 'Disconnected',
  /** The hub connection is connecting. */
  Connecting = 'Connecting',
  /** The hub connection is connected. */
  Connected = 'Connected',
  /** The hub connection is disconnecting. */
  Disconnecting = 'Disconnecting',
  /** The hub connection is reconnecting. */
  Reconnecting = 'Reconnecting'
}

export default function useNotification(retryCount = 30, retryInterval = 6000, hubUrl = '/cps-ws') {
  const hubSubsEvent = new Set<string>();
  const websocket = new RxSignalR({ retryCount, retryInterval, hubUrl});
  const $destory: Subject<boolean> = new Subject<boolean>();
  function start(): Observable<any> {
    if (websocket.state === HubConnectionState.Disconnected) {
      return websocket.start();
    }
    throw new Error(`Websocket connection not available now, state = ${websocket.state}`);
  }

  function connect(eventName: string): Observable<any> {
    hubSubsEvent.add(eventName);
    return websocket.on(eventName);
  }

  function rateEvent() {
    const eventName = 'rate';
    hubSubsEvent.add(eventName);
    return connect(eventName).pipe(
      takeUntil($destory),
      debounceTime(2000),
    );
  }

  function getSocketState() {
    return websocket.state;
  }

  function dispose() {
    $destory.next(true);
    websocket.stop();
  }

  return {
    start,
    rateEvent,
    dispose,
    getSocketState,
  };
}
