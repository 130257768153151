import moment, { DurationInputArg2, Moment } from 'moment';

// 等我有空這邊改成抽象跟pure function，醬我們就不用綁訂moment惹， ㄅ欠
export interface ITimeService {
  now: () => moment.Moment;
  diff: (timeA: moment.Moment, timeB: moment.Moment) => number;
  getMomentBySecond: (second: number) => moment.Moment;
  getMomentByDate: (date: Date | string) => moment.Moment;
  getSystemFormat: (time: moment.Moment) => string;
  format: (time: moment.Moment, _format: string) => string;
  changeOffset: (newOffset: number) => void;
  addTime: (time: moment.Moment, value: number, unit: moment.unitOfTime.Diff) => moment.Moment;
}

export default function useTimeService(offset: number): ITimeService {
  let _offset = offset;
  function getMomentByDate(date: Date | string) {
    return date ? moment(date).utcOffset(_offset) : moment().utcOffset(_offset);
  }
  function getMomentBySecond(second: number) {
    return moment(second * 1000);
  }
  function getSystemFormat(time: Moment) {
    return time.format(`YYYY/MM/DD  HH:mm:ss(UTC +${_offset})`);
  }
  function now() {
    return moment().utcOffset(_offset);
  }
  function diff(timeA: Moment, timeB: Moment) {
    return timeA.diff(timeB, 'seconds');
  }
  function format(time: Moment, _format: string) {
    return time.format(_format);
  }
  function changeOffset(newOffset: number) {
    _offset = newOffset;
  }
  function addTime(time: Moment, value: number, unit: DurationInputArg2) {
    return time.add(value, unit);
  }
  return {
    now,
    diff,
    getMomentBySecond,
    getMomentByDate,
    getSystemFormat,
    format,
    changeOffset,
    addTime,
  };
}
