<template>
  <div v-if="machineContext">
    <div class="flex items-center">
      <h2 class="text-2xl font-bold text-black-main mr-auto mt-auto mb-auto">{{ t("Deposit_Detail") }}</h2>
      <button class="flex flex-col items-center" @click="isShowGuideModal = true">
        <div class="info-icon"></div>
        <div class="text-gray-guide text-xs mt-1">{{ t("Deposit_Guide") }}</div>
      </button>
    </div>
    <div class="container my-6">
      <div class="flex items-center">
        <img class="h-auto outline-none max-w-full pr-1" src="@/assets/svg/Stopwatch.svg" />
        <span class="text-lg font-medium text-warning">{{ countdown }}</span>
      </div>
      <div class="my-3 py-2 px-3 bg-warning rounded-md border border-solid border-warning">
        <div class="flex items-center">
          <img class="h-auto outline-none max-w-full pr-1 self-start" src="@/assets/svg/Alert.svg">
          <p class="text-xs font-medium text-portal-darkgrey">{{ t("Deposit_Detail_Tips") }}</p>
        </div>
      </div>
      <div class="my-4 py-4 px-5 rounded-xl bg-black-secondary text-white">
        <div>
          <p class="text-xs opacity-70">{{ t("Deposit_Transaction_Id") }}</p>
          <span class="text-sm">{{ machineContext.ticketId }}</span>
        </div>
        <div class="mt-3.5">
          <p class="text-xs opacity-70">{{ t("Deposit_Transaction_Date") }}</p>
          <span class="text-sm">{{ offetTime }}</span>
        </div>
        <div class="mt-3.5">
          <p class="text-xs opacity-70">{{ fromCurrecnyName }}/{{ toCurrencyName }}</p>
          <span class="text-sm">{{ amountFormat(6)(rate) }}</span>
        </div>
      </div>
    </div>
    <p v-if="isShowKVND" class="text-portal-darkgrey text-xs">{{ t("Deposit_Show_VND_Rate") }}</p>
    <div class="container my-6">
      <p class="text-xs text-portal-grey">{{ t("Deposit_Transfer_Network") }}</p>
      <div class="my-2 rounded-md">
          <span class="text-xl">{{ TokenStandards[machineContext.tokenStandard] }}</span>
      </div>
    </div>

    <div class="container my-6">
      <p class="text-xs text-portal-grey">{{ t("Deposit_Transfer_Amount") }} {{fromCurrecnyName}}</p>
      <div class="my-2 p-3 rounded-md bg-detail text-black-secondary">
        <div class="flex items-center justify-between">
          <span class="text-xl font-bold">{{ amountFormat(8)(machineContext.fromAmount) }}</span>
          <button class="circle copy-icon"
            v-clipboard:copy="amountFormat(8)(machineContext.fromAmount)"
            v-clipboard:success="prompt.copySuccess"
          />
        </div>
      </div>
      <p class="text-xs text-portal-darkgrey text-right">{{t("Deposit_Estimate")}}  {{amountFormat(2)(toAmount)}} {{toCurrencyName}}</p>
      <p class="text-xs text-portal-grey">{{t("Deposit_Wallet_Address")}}</p>
      <div class="my-2 p-3 rounded-md bg-detail text-black-secondary">
        <p class="text-sm leading-normal font-bold break-words">{{machineContext.toAddress}}</p>
        <div class="flex flex-row-reverse mt-4">
          <button class="circle copy-icon"
            v-clipboard:copy="machineContext.toAddress"
            v-clipboard:success="prompt.copySuccess"
           />
          <button class="circle scan-icon mr-4" @click="onQrCode" />
        </div>
      </div>
    </div>
    <CpsButton class="w-full" @click="onSubmit">{{t("Deposit_Enter_Hash_Id")}}</CpsButton>
    <CpsGuideModal :isShowModal="isShowGuideModal" :guideContent="guideContent" @close="isShowGuideModal = false"/>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent, inject, Ref, ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import useAmount from '@/utilities/composition-functions/amount';
import useCurrecny from '@/utilities/composition-functions/currency';
import { DepositMachineType, DepositTransactionSymbol } from '@/utilities/composition-functions/xstate.machine';
import useTimeService, { ITimeService } from '@/utilities/composition-functions/time';
import { IPrompt, PromptSymbol } from '@/utilities/composition-functions/prompt';
import useKVNDHandler from '@/utilities/composition-functions/kvnd-handler';
import CpsButton from '@/components/utils/CpsButton.vue';
import {TokenStandards} from '@/models';
import CpsGuideModal from '@/components/utils/CpsGuideModal.vue';

interface IGuideContent {
  title: string;
  img: string;
}

function useCountdown(timeService: ITimeService) {
  function startCount(targetTimeSeconds: number) {
    const seconds = !Number.isNaN(+targetTimeSeconds) && targetTimeSeconds > 0 ? targetTimeSeconds : 1;
    const targetTime = timeService.now().add(seconds, 'seconds');
    return timer(0, 1000).pipe(
      takeUntil(timer(seconds * 1000)),
      map(() => timeService.diff(targetTime, timeService.now())),
    );
  }
  return {
    startCount,
  };
}

export default defineComponent({
  name: 'DepositPending',
  components: {
    CpsButton,
    CpsGuideModal,
  },
  setup() {
    const { t } = useI18n();
    const { cryptoCurrencyToKVNDRate, VNDToKVND } = useKVNDHandler();
    const machine = inject<Ref<DepositMachineType>>(DepositTransactionSymbol);
    const machineContext = machine!.value.state.context;
    const { getNameByCurrency } = useCurrecny();
    const { amountFormat} = useAmount();
    const timeService = useTimeService(machineContext.offset);
    const { startCount } = useCountdown(timeService);
    const countdown = ref('');
    const offetTime = ref('');
    const rate = ref(0);
    const toAmount = ref(0);
    const prompt = inject<IPrompt>(PromptSymbol);
    const fromCurrecnyName = computed(() => machine && getNameByCurrency(machineContext.fromCurrency));
    const toCurrencyName = computed(() => machine && getNameByCurrency(machineContext.toCurrency));
    const isShowKVND = computed(() => machineContext.isShowKVND);
    const isShowGuideModal = ref<boolean>(false);
    const guideContent: Ref<IGuideContent[]> = ref([
      {
        title: t('Deposit_Detail_Guide_Tips1'),
        img: 'deposit-detail-guide-1.png',
      },
      {
        title: t('Deposit_Detail_Guide_Tips2'),
        img: 'deposit-detail-guide-2.png',
      },
    ]);

    async function init() {
      if (machineContext.isShowKVND) {
        if (machineContext.rate) {
          rate.value = cryptoCurrencyToKVNDRate(machineContext.rate);
        }
        if (machineContext.toAmount) {
          toAmount.value = VNDToKVND(machineContext.toAmount);
        }
      } else {
        toAmount.value = machineContext.toAmount as number;
        rate.value = machineContext.rate as number;
      }
      offetTime.value = timeService.getSystemFormat(timeService.getMomentByDate(machineContext.rateDate));
      const count = timeService.diff(timeService.addTime(timeService.getMomentByDate(machineContext.rateDate), 5, 'minutes'), timeService.now());
      startCount(count).subscribe((s) => {
        countdown.value = timeService.format(timeService.getMomentBySecond(s), 'mm:ss');
      });
    }
    function onSubmit() {
      machine!.value.send('Next');
    }
    function onQrCode() {
      machine!.value.send({ type: 'QrCode'});
    }
    return {
      t,
      machineContext,
      init,
      machine,
      rate,
      toAmount,
      countdown,
      timeService,
      offetTime,
      fromCurrecnyName,
      toCurrencyName,
      prompt,
      onSubmit,
      amountFormat,
      onQrCode,
      TokenStandards,
      isShowGuideModal,
      guideContent,
      isShowKVND,
    };
  },
  created() {
    this.init();
  },
});
</script>
<style lang="css" scoped>
.bg-warning {
  background-color: #f4e8dd;
}
.border-warning {
  border-color: #f19130
}
.text-warning {
  color: #ff9326
}
.bg-detail {
  background-color: #f4f4f4;
}
.copy-icon {
  background-size: 50%;
  background-color: #ffd530;
  background-image: url('~@/assets/svg/Copy.svg');
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
}
.scan-icon {
  background-size: 50%;
  background-color: #ffd530;
  background-image: url('~@/assets/svg/Scan.svg');
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
}

.text-gray-guide {
  color: #505050
}

.info-icon {
  background-image: url('~@/assets/svg/InfoOutline.svg');
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
}
</style>
