import {
  GetDepositTransactionResponse,
  GetDepositResponse,
  CreateDepositRequest,
  CreateDepositResponse,
  UpdateDepositRequest,
  IGetDepositLimitSettingResponse,
} from '@/models/api/transaction';
import { HttpHandler, Interceptor } from '@/utilities/http.handler';

class TransactionHandler {
  handler: HttpHandler;

  constructor() {
    this.handler = new HttpHandler('/api/transaction');
  }

  @Interceptor()
  getDepositAsync(): Promise<GetDepositResponse> {
    return this.handler.get<GetDepositResponse>('dp');
  }

  @Interceptor()
  getDepositLimitAsync(): Promise<IGetDepositLimitSettingResponse> {
    return this.handler.get<IGetDepositLimitSettingResponse>('dp-limit');
  }

  @Interceptor()
  createDepositAsync(request: CreateDepositRequest): Promise<CreateDepositResponse> {
    return this.handler.post<GetDepositTransactionResponse>('dptx', request);
  }

  @Interceptor()
  updateDepositAsync(request: UpdateDepositRequest): Promise<void> {
    return this.handler.put('dptx', request);
  }
}

export const transactionHandler = new TransactionHandler();

export default transactionHandler;
