import { Currency, TransactionType, TokenStandards } from '@/models';
import { GetDepositResponse } from '@/models/api/transaction';
import {
  AnyEventObject, assign, DoneInvokeEvent, Interpreter,
} from 'xstate';
import { createMachine } from 'xstate/lib/Machine';
import TransactionHandler from '@/api/transaction';

export const TRANSACTION_STATES = {
  NEW: 'New',
  PENDING: 'Pending',
  HASHID: 'HashId',
  SUBMIT: 'Submit',
  RESULT: 'Result',
  SUCCESS: 'Success',
  ERROR: 'ErrorPage',
  QRCODE: 'QrCode',
  WALLETNOTAVAILABLE: 'WalletNotAvailable',
};

export const TRANSACTION = {
  NONE: 'None',
  DEPOSIT: 'Deposit',
  WITHDRAWAL: 'Withdrawal',
};

export const TRANSACTION_EVENTS = {
  NEXT: 'Next',
  CANCEL: 'Cancel',
  DEPOSIT: 'Deposit',
  RESULT: 'Result',
  ERROR: 'Error',
  QRCODE: 'QrCode',
  WALLETNOTAVAILABLE: 'WalletNotAvailable',
};

export interface ITransactionContext {
  fromCurrency: Currency;
  toCurrency: Currency;
  offset: number;
  transactionType: TransactionType;
  ticketId: string;
  rate: number | null;
  toAddress: string;
  errorCode: string;
  fromAmount: number | null | string;
  toAmount: number | null | string;
  rateDate: Date | string;
  tokenStandard: TokenStandards;
  isShowKVND: boolean;
}

export type TransactionStateType<T> = {
  value: T;
  context: ITransactionContext;
}

export type TransactionEventType =
  | { type: 'Next'; payload: any }
  | { type: 'Cancel' }
  | { type: 'Error'; errorCode: string}
  | { type: 'QrCode'}
  | { type: 'Result'}
  | { type: 'WalletNotAvailable'};

export type TransactionStateTypes =
  | TransactionStateType<'None'>
  | TransactionStateType<'Deposit'>
  | TransactionStateType<'Withdrawal'>
  | TransactionStateType<'Result'>
  | TransactionStateType<'Success'>
  | TransactionStateType<'ErrorPage'>
  | TransactionStateType<{ Deposit: 'New' }>
  | TransactionStateType<{ Deposit: 'Pending' }>
  | TransactionStateType<{ Deposit: 'HashId' }>
  | TransactionStateType<{ Deposit: 'QrCode' }>;

export type DepositMachineType = Interpreter<ITransactionContext, any, TransactionEventType, TransactionStateTypes>;

export function useDepositTransactionMachine() {
  function getMachine(transactionType: TransactionType, response: GetDepositResponse) {
    return createMachine<
      ITransactionContext,
      TransactionEventType,
      TransactionStateTypes
    >({
      initial: TRANSACTION.NONE,
      context: {
        fromCurrency: response.fromCurrency,
        toCurrency: response.toCurrency,
        offset: 8,
        ticketId: '',
        transactionType,
        rate: null,
        toAddress: '',
        errorCode: '',
        fromAmount: null,
        toAmount: null,
        rateDate: '',
        tokenStandard: TokenStandards.TRC20,
        isShowKVND: response.isShowKVND,
      },
      states: {
        [TRANSACTION.NONE]: {
          on: {
            [TRANSACTION_EVENTS.NEXT]: [{
              target: TRANSACTION.DEPOSIT,
              cond: (context: ITransactionContext) => context.transactionType === TransactionType.Deposit,
            }],
          },
        },
        [TRANSACTION.DEPOSIT]: {
          type: 'compound',
          initial: TRANSACTION_STATES.NEW,
          id: TRANSACTION.DEPOSIT,
          invoke: {
            src: async () => TransactionHandler.getDepositAsync(),
            onDone: {
              actions: assign({
                toAddress: (_context, event: DoneInvokeEvent<GetDepositResponse>) => event.data.toAddress,
                fromCurrency: (_context, event: DoneInvokeEvent<GetDepositResponse>) => event.data.fromCurrency,
                toCurrency: (_context, event: DoneInvokeEvent<GetDepositResponse>) => event.data.toCurrency,
                offset: (_context, event: DoneInvokeEvent<GetDepositResponse>) => event.data.offset,
                ticketId: (_context, event: DoneInvokeEvent<GetDepositResponse>) => event.data.ticketId,
                rate: (_context, event: DoneInvokeEvent<GetDepositResponse>) => event.data.rate,
                fromAmount: (_context, event: DoneInvokeEvent<GetDepositResponse>) => event.data.fromAmount,
                toAmount: (_context, event: DoneInvokeEvent<GetDepositResponse>) => event.data.toAmount,
                rateDate: (_context, event: DoneInvokeEvent<GetDepositResponse>) => event.data.rateDate,
                tokenStandard: (_context, event: DoneInvokeEvent<GetDepositResponse>) => event.data.tokenStandard,
                isShowKVND: (_context, event: DoneInvokeEvent<GetDepositResponse>) => event.data.toCurrency === Currency.VND && event.data.isShowKVND,
              }),
            },
            onError: {
              target: TRANSACTION_STATES.RESULT,
              actions: assign({ errorCode: (_context, event) => event.data }),
            },
          },
          states: {
            [TRANSACTION_STATES.NEW]: {
              always: [
                {
                  target: TRANSACTION_STATES.PENDING,
                  cond: (context: ITransactionContext) => !!context.ticketId,
                },
              ],
              on: {
                [TRANSACTION_EVENTS.NEXT]: [{
                  target: TRANSACTION_STATES.PENDING,
                  actions: assign<ITransactionContext, AnyEventObject>({
                    rate: (context: ITransactionContext, { payload }) => payload.rate,
                    rateDate: (context: ITransactionContext, { payload }) => payload.rateDate,
                    toAddress: (_context: ITransactionContext, { payload }) => payload.toAddress,
                    ticketId: (_context: ITransactionContext, { payload }) => payload.ticketId,
                    fromAmount: (_context: ITransactionContext, { payload }) => payload.fromAmount,
                    toAmount: (_context: ITransactionContext, { payload }) => payload.toAmount,
                    tokenStandard: (_context: ITransactionContext, { payload }) => payload.tokenStandard,
                  }),
                  cond: (context: ITransactionContext) => !context.ticketId,
                }],
                [TRANSACTION_EVENTS.ERROR]: [
                  {
                    target: TRANSACTION_STATES.RESULT,
                    actions: assign<ITransactionContext, AnyEventObject>({errorCode: (context: ITransactionContext, { errorCode }) => errorCode}),
                  },
                ],
              },
            },
            [TRANSACTION_STATES.PENDING]: {
              on: {
                [TRANSACTION_EVENTS.NEXT]: {
                  target: TRANSACTION_STATES.HASHID,
                },
                [TRANSACTION_EVENTS.QRCODE]: {
                  target: TRANSACTION_STATES.QRCODE,
                },
              },
            },
            [TRANSACTION_STATES.HASHID]: {
              on: {
                [TRANSACTION_EVENTS.NEXT]: {
                  target: TRANSACTION_STATES.RESULT,
                },
                [TRANSACTION_EVENTS.ERROR]: [
                  {
                    target: TRANSACTION_STATES.RESULT,
                    actions: assign<ITransactionContext, AnyEventObject>({errorCode: (context: ITransactionContext, { errorCode }) => errorCode}),
                  },
                ],
                [TRANSACTION_EVENTS.CANCEL]: {
                  target: TRANSACTION_STATES.PENDING,
                },
              },
            },
            [TRANSACTION_STATES.QRCODE]: {
              on: {
                [TRANSACTION_EVENTS.CANCEL]: {
                  target: TRANSACTION_STATES.PENDING,
                },
              },
            },
            [TRANSACTION_STATES.RESULT]: {
              type: 'final',
            },
          },
          onDone: TRANSACTION_STATES.RESULT,
        },
        [TRANSACTION_STATES.RESULT]: {
          on: {
            [TRANSACTION_EVENTS.NEXT]: [
              {
                target: TRANSACTION_STATES.ERROR,
                cond: (context: ITransactionContext) => context.errorCode && context.errorCode !== '70003',
              },
              {
                target: TRANSACTION_STATES.WALLETNOTAVAILABLE,
                actions: assign<ITransactionContext, AnyEventObject>({errorCode: () => ''}),
                cond: (context: ITransactionContext) => context.errorCode === '70003',
              },
              {
                target: TRANSACTION_STATES.SUCCESS,
              }],
          },
        },
        [TRANSACTION_STATES.ERROR]: {},
        [TRANSACTION_STATES.SUCCESS]: {},
        [TRANSACTION_STATES.WALLETNOTAVAILABLE]: {
          on: {
            [TRANSACTION_EVENTS.NEXT]: {
              target: TRANSACTION.DEPOSIT,
              actions: assign<ITransactionContext, AnyEventObject>({errorCode: ''}),
            },
          },
        },
      },
    });
  }
  return {
    getMachine,
  };
}
export const DepositTransactionSymbol = Symbol('DepositTransactionSymbol');
