
import { PromptIcon } from '@/utilities/composition-functions/prompt';
import { timer } from 'rxjs';
import {
  computed, defineComponent, ref, watchEffect,
} from 'vue';

export default defineComponent({
  name: 'CpsPrompt',
  setup(props) {
    const isShow = ref(false);
    const iconName = computed(() => {
      switch (props.icon) {
        case PromptIcon.success:
          return 'Success.svg';
        default:
          return 'Info.svg';
      }
    });
    return {
      isShow,
      iconName,
    };
  },
  created() {
    watchEffect(() => {
      if (this.value) {
        this.isShow = true;
        timer(this.durations).subscribe(() => {
          this.isShow = false;
          this.$emit('update:value', false);
        });
      }
    });
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    durations: {
      type: Number,
      default: 2000,
    },
    value: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Number,
      default: PromptIcon.info,
    },
  },
});
