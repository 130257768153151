import { Currency } from '@/models';

export default function useCurrecny() {
  function getNameByCurrency(currency: Currency, isShowKVND?: boolean) {
    switch (currency) {
      case Currency.CNY:
        return 'CNY';
      case Currency.ETH:
        return 'ETH';
      case Currency.USDC:
        return 'USDC';
      case Currency.USDT:
        return 'USDT';
      case Currency.VND:
        return isShowKVND ? 'KVND' : 'VND';
      default:
        return 'NA';
    }
  }
  function getRateIconByCurrency(currency: Currency) {
    switch (currency) {
      case Currency.ETH:
        return 'rate-eth-icon';
      case Currency.USDC:
        return 'rate-usdc-icon';
      case Currency.USDT:
        return 'rate-usdt-icon';
      default:
        return 'NA';
    }
  }
  function getInputIconByCurrency(currency: Currency) {
    switch (currency) {
      case Currency.ETH:
        return 'input-eth-icon';
      case Currency.USDC:
        return 'input-usdc-icon';
      case Currency.USDT:
        return 'input-usdt-icon';
      case Currency.CNY:
        return 'input-rmb-icon';
      case Currency.VND:
        return 'input-vnd-icon';
      default:
        return 'NA';
    }
  }
  return {
    getNameByCurrency,
    getRateIconByCurrency,
    getInputIconByCurrency,
  };
}
