import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import { VueClipboard } from '@soerenmartius/vue3-clipboard';
import ErrorHandler from './utilities/error-handler';
import './assets/styles/tailwind.css';
import App from './App.vue';
import zh from './lang/zh-cn.json';
import en from './lang/en-us.json';
import vn from './lang/vi-vn.json';

const i18n = createI18n({
  legacy: false,
  locale: 'zh-cn',
  fallbackLocale: 'zh-cn',
  messages: {
    'zh-cn': zh,
    'en-us': en,
    'vi-vn': vn,
  },
});
const app = createApp(App);
app.use(i18n);
app.use(ErrorHandler);
app.use(VueClipboard);
app.mount('#app');
