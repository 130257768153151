
import { DepositMachineType, DepositTransactionSymbol } from '@/utilities/composition-functions/xstate.machine';
import CpsButton from '@/components/utils/CpsButton.vue';
import qrcode from 'qrcode-generator';
import { defineComponent, inject, Ref } from 'vue';
import { useI18n } from 'vue-i18n';

function useQrCode() {
  function generateQrCode(url: string) {
    const qr = qrcode(4, 'L');
    qr.addData(url);
    qr.make();
    return qr.createDataURL(4, 12);
  }
  return {
    generateQrCode,
  };
}

export default defineComponent({
  name: 'QrCode',
  components: {
    CpsButton,
  },
  setup() {
    const { t } = useI18n();
    const machine = inject<Ref<DepositMachineType>>(DepositTransactionSymbol);
    const machineContext = machine!.value.state.context;
    const { generateQrCode } = useQrCode();
    const qrCodeDataUrl = generateQrCode(machineContext.toAddress);
    function onClose() {
      machine!.value.send({type: 'Cancel'});
    }
    return {
      t,
      qrCodeDataUrl,
      onClose,
    };
  },
});
