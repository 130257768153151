<template>
  <div class="cps-guide-modal cover-background absolute w-full h-full top-0 left-0" v-if="isShowModal">
    <div class="container flex flex-col relative">
      <div class="header flex mb-8 p-5">
        <button class="close-icon ml-auto" @click="$emit('close')"></button>
      </div>
      <div class="body overflow-hidden">
        <div class="slider-content__container w-full flex transition-transform duration-200 ease-out" :style="`transform: translateX(${currentSelectedSliderIndex * -100}%)`">
          <div class="content" v-for="(content, index) in guideContent" :key="index" @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd">
            <div class="content__container slider-content-background flex flex-col py-10 px-5">
              <div class="guide-title flex justify-center">
                <h3 class="mb-6 font-bold text-xl inline-block content-title-black mx-5">{{content.title}}</h3>
              </div>
              <div class="guide-img">
                <img class="block w-full" :src="require(`../../assets/guide-images/${locale}/${content.img}`)" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="slider-button-group absolute">
          <div class="slider-button-group__container flex">
            <div class="slider-button rounded-full w-2 h-2 mx-1 cursor-pointer slider-button-background" :class="{isSelected: currentSelectedSliderIndex === index}"  v-for="(content, index) in guideContent" :key="index" @click="clickMoveSlider(index)"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'CpsGuideModal',
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
    guideContent: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { locale } = useI18n();
    const currentSelectedSliderIndex = ref<number>(0);
    const clickMoveSlider = (index: number) => {
      currentSelectedSliderIndex.value = index;
    };
    const touchStartX = ref<number>(0);
    const touchMoveDirection = ref<string>('');
    const onTouchStart = (e: TouchEvent) => {
      touchStartX.value = e.changedTouches[0].pageX;
    };
    const onTouchMove = (e: TouchEvent) => {
      const touchMoveXDistance = e.changedTouches[0].pageX - touchStartX.value;
      if (touchMoveXDistance > 0) {
        touchMoveDirection.value = 'right';
      } else if (touchMoveXDistance < 0) {
        touchMoveDirection.value = 'left';
      }
    };
    const onTouchEnd = () => {
      // 右滑
      if (touchMoveDirection.value === 'right') {
        if (currentSelectedSliderIndex.value <= props.guideContent.length && currentSelectedSliderIndex.value > 0) {
          currentSelectedSliderIndex.value -= 1;
        }
      // 左滑
      } else if (touchMoveDirection.value === 'left') {
        if (currentSelectedSliderIndex.value < props.guideContent.length - 1) {
          currentSelectedSliderIndex.value += 1;
        }
      }
      touchMoveDirection.value = '';
    };
    return {
      locale,
      currentSelectedSliderIndex,
      clickMoveSlider,
      onTouchStart,
      onTouchMove,
      onTouchEnd,
    };
  },
});
</script>

<style lang="css" scoped>
.cps-guide-modal {
  z-index: 5000;
}
.cover-background {
  background: rgba(0, 0, 0, 0.7);
}
.close-icon {
  background-image: url('~@/assets/svg/Close.svg');
  width: 32px;
  height: 32px;
}
.content-title-black {
  color: #262626;
}
.slider-button-background {
  background: #EBEBEB;
}
.content {
  width: 100%;
  flex-shrink: 0;
}
.slider-content-background {
  background: #F4F4F4;
}
.content__container {
  max-height: calc(100vh - 131px);
  border-radius: 24px;
  max-width: 325px;
  margin: 0 auto;
  overflow-y: auto;
}
.slider-button-group {
  left: 50%;
  transform: translateX(-50%);
  bottom: -24px;
}
.isSelected {
  background: #FFD530;
}
</style>
