
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'CpsGuideModal',
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
    guideContent: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { locale } = useI18n();
    const currentSelectedSliderIndex = ref<number>(0);
    const clickMoveSlider = (index: number) => {
      currentSelectedSliderIndex.value = index;
    };
    const touchStartX = ref<number>(0);
    const touchMoveDirection = ref<string>('');
    const onTouchStart = (e: TouchEvent) => {
      touchStartX.value = e.changedTouches[0].pageX;
    };
    const onTouchMove = (e: TouchEvent) => {
      const touchMoveXDistance = e.changedTouches[0].pageX - touchStartX.value;
      if (touchMoveXDistance > 0) {
        touchMoveDirection.value = 'right';
      } else if (touchMoveXDistance < 0) {
        touchMoveDirection.value = 'left';
      }
    };
    const onTouchEnd = () => {
      // 右滑
      if (touchMoveDirection.value === 'right') {
        if (currentSelectedSliderIndex.value <= props.guideContent.length && currentSelectedSliderIndex.value > 0) {
          currentSelectedSliderIndex.value -= 1;
        }
      // 左滑
      } else if (touchMoveDirection.value === 'left') {
        if (currentSelectedSliderIndex.value < props.guideContent.length - 1) {
          currentSelectedSliderIndex.value += 1;
        }
      }
      touchMoveDirection.value = '';
    };
    return {
      locale,
      currentSelectedSliderIndex,
      clickMoveSlider,
      onTouchStart,
      onTouchMove,
      onTouchEnd,
    };
  },
});
