import { IApiResponse } from '@/models';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';
import { RequestInterceptors } from '@higgs/utils';
import Cookies from 'js-cookie';

export function Interceptor() {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;
    const culture = Cookies.get('culture') || 'zh-cn';
    let localization: any = null;
    const getLocalization = async () => {
      const langFile = await import(`@/lang/${culture}.json`);
      localization = langFile;
    };
    getLocalization();
    descriptor.value = async function (args: any) {
      try {
        const result = await originalMethod.bind(this)(args);
        const { data } = result;
        return data.statusCode ? Promise.reject(data.statusCode) : Promise.resolve(data.data);
      } catch (error) {
        if (error.statusCode === '410') {
          window.location.reload();
        } else if (error.statusCode === '429') {
          window.alert(`${localization.Error_ApiReachedLimit}`);
        }
        return Promise.reject(error.statusCode);
      }
    };
  };
}

export class HttpHandler {
  private http: AxiosInstance;

  private requestConfig: AxiosRequestConfig = { };

  constructor(baseURL = '', requestConfig: AxiosRequestConfig = {}) {
    this.http = axios.create({
      baseURL,
      ...requestConfig,
      paramsSerializer(params) {
        return qs.stringify(params, { skipNulls: true, allowDots: true });
      },
    });

    this.http.interceptors.request.use((config) => RequestInterceptors(config));

    this.http.interceptors.response.use(HttpHandler.responseSuccess, HttpHandler.responseError);
  }

  request(config: AxiosRequestConfig): Promise<any> {
    return this.http.request(config);
  }

  static qs(request: any, config?: qs.IStringifyOptions | undefined): string {
    return qs.stringify(request, config || { skipNulls: true, allowDots: true });
  }

  static responseError(error: any) {
    const response: IApiResponse = {
      statusCode: error.response.status.toString(),
      data: null,
      errorMsg: error.response.data,
    };
    return Promise.reject(response);
  }

  static responseSuccess(v: AxiosResponse<IApiResponse>) {
    return v;
  }

  get<T>(url: string, requestData: any = {}, requestConfig: AxiosRequestConfig = {}): Promise<any> {
    return this.baseRequest<T>('get', url, requestData, requestConfig);
  }

  post<T>(url: string, requestData: any = {}, requestConfig: AxiosRequestConfig = {}): Promise<any> {
    return this.baseRequest<T>('post', url, requestData, requestConfig);
  }

  put<T>(url: string, requestData: any = {}, requestConfig: AxiosRequestConfig = {}): Promise<any> {
    return this.baseRequest<T>('put', url, requestData, requestConfig);
  }

  delete<T>(url: string, requestData: any = {}, requestConfig: AxiosRequestConfig = {}): Promise<any> {
    return this.baseRequest<T>('delete', url, requestData, requestConfig);
  }

  private baseRequest<T>(
    method: any,
    url: string,
    requestData = {},
    requestConfig: AxiosRequestConfig = {},
  ): Promise<IApiResponse<T>> {
    const params = method === 'get' || method === 'delete' ? requestData : undefined;
    const data = method === 'post' || method === 'put' ? requestData : undefined;
    const options: AxiosRequestConfig = {
      url, method, params, data, ...requestConfig,
    };
    return this.http.request(options);
  }
}

export default HttpHandler;
