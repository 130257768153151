<template>
  <div class="container mt-24">
    <div class="wallet-not-available" />
    <h1 class="text-3xl text-center font-bold mt-9">{{t("Deposit_Wallet_Not_Available")}}</h1>
    <div class="mt-4 text-center font-medium text-xs text-portal-darkgrey">
      <p>{{t("Deposit_Wallet_Not_Available_Desciption")}}</p>
    </div>
    <CpsButton class="mt-11 mx-auto w-7/12" @click="restart">{{t("Deposit_Wallet_Not_Available_Return")}}</CpsButton>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import CpsButton from '@/components/utils/CpsButton.vue';
import { DepositMachineType, DepositTransactionSymbol } from '@/utilities/composition-functions/xstate.machine';

export default defineComponent({
  name: 'WalletNotAvailable',
  components: {
    CpsButton,
  },
  setup() {
    const { t } = useI18n();
    const machine = inject<Ref<DepositMachineType>>(DepositTransactionSymbol);
    function restart() {
      machine!.value.send('Next');
    }
    return {
      t,
      restart,
    };
  },
});
</script>

<style lang="css" scoped>
.wallet-not-available {
  background-image: url('~@/assets/svg/WalletError.svg');
  width: 105px;
  height: 100px;
  margin: auto;
  position: relative;
  background-repeat: no-repeat;
}
/* .result-page-container {
  margin-top: 100px;
  h1 {
  margin-top: 45px;
  }
  button {
    width: 170px;
    margin: auto;
    margin-top: 35px;
  }
  p:first-of-type {
    margin-top: 25px;
  }
}
.wallet-not-available {
  background-image: url('~@/assets/svg/WalletError.svg');
  width: 105px;
  height: 100px;
  margin: auto;
  position: relative;
  background-repeat: no-repeat;
} */
</style>
