
import { DepositTransactionSymbol } from '@/utilities/composition-functions/xstate.machine';
import { defineComponent, inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ErrorPage',
  setup() {
    const { t } = useI18n();
    const machine = inject(DepositTransactionSymbol) as any;
    // machine service 開始前的錯誤都預設顯示 501
    const errorCode = ref('501');
    if (machine.value) {
      const machineContext = machine.value.state.context;
      errorCode.value = machineContext.errorCode;
    }

    return {
      t,
      errorCode,
    };
  },
});
