
import {
  computed,
  defineComponent,
  inject,
  provide,
  reactive,
  Ref,
  ref,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import CpsNumberInput from '@/components/utils/CpsNumberInput.vue';
import CpsButton from '@/components/utils/CpsButton.vue';
import useNotification, {HubConnectionState} from '@/utilities/composition-functions/notification';
import {concatMap, filter} from 'rxjs/operators';
import useCurrecny from '@/utilities/composition-functions/currency';
import {from, Subscription} from 'rxjs';
import {CreateDepositRequest, CreateDepositResponse, IGetDepositLimitSettingResponse} from '@/models/api/transaction';
import TransactionHandler, {transactionHandler} from '@/api/transaction';
import {
  DepositMachineType,
  DepositTransactionSymbol,
} from '@/utilities/composition-functions/xstate.machine';
import useAmount from '@/utilities/composition-functions/amount';
import {FormGroup, Validators} from '@higgs/utils';
import {IPrompt, PromptSymbol} from '@/utilities/composition-functions/prompt';
import Decimal from 'decimal.js';
import {Currency, TokenStandardsList, TokenStandards} from '@/models';
import RadioButton from '@/components/utils/RadioButton.vue';
import CpsGuideModal from '@/components/utils/CpsGuideModal.vue';
import useKVNDHandler from '@/utilities/composition-functions/kvnd-handler';

interface IGuideContent {
  title: string;
  img: string;
}

export default defineComponent({
  name: 'DepositNew',
  components: {
    CpsNumberInput,
    CpsButton,
    RadioButton,
    CpsGuideModal,
  },
  setup() {
    const { t } = useI18n();
    const notification = useNotification();
    const { getNameByCurrency, getRateIconByCurrency, getInputIconByCurrency } = useCurrecny();
    const { amountFormat, formatDecimal, metricNumberFormat } = useAmount();
    const { cryptoCurrencyToKVNDRate, VNDToKVND } = useKVNDHandler();
    const machine = inject<Ref<DepositMachineType>>(DepositTransactionSymbol);
    const machineContext = ref(machine!.value.state.context);
    const prompt = inject<IPrompt>(PromptSymbol);
    const rate = ref(0);
    const grayTextSwitch = ref(false);
    const formGroup = reactive(new FormGroup({}));
    const isValid = ref(true);
    const limitData = ref<IGetDepositLimitSettingResponse | null>(null);
    const formattedMinLimit = ref('');
    const formattedMaxLimit = ref('');
    const fromCurrenyRateIcon = computed(() => machine && getRateIconByCurrency(machineContext.value.fromCurrency));
    const fromCurrenyInputIcon = computed(() => machine && getInputIconByCurrency(machineContext.value.fromCurrency));
    const toCurrenyInputIcon = computed(() => machine && getInputIconByCurrency(machineContext.value.toCurrency));
    const fromCurrecnyName = computed(() => machine && getNameByCurrency(machineContext.value.fromCurrency));
    const toCurrencyName = computed(() => machine && getNameByCurrency(machineContext.value.toCurrency));
    const isShowKVND = computed(() => machineContext.value.isShowKVND);
    const calculatedFromAmount = ref<string | null>(null);
    const calculatedToAmount = ref<string | null>(null);
    const isShowGuideModal = ref<boolean>(false);
    // 只有USDT改成顯示小數點後兩位，其餘維持小數點後8位
    const decimalPlaces = computed(() => (fromCurrecnyName.value === 'USDT' ? 2 : 8));
    const guideContent: Ref<IGuideContent[]> = ref([
      {
        title: t('Deposit_Guide_Tips1'),
        img: 'deposit-guide-1.png',
      },
      {
        title: t('Deposit_Guide_Tips2'),
        img: 'deposit-guide-2.png',
      },
    ]);

    const availableTokenStandard = computed(() => {
      if (machineContext.value.fromCurrency === Currency.ETH) {
        return TokenStandardsList.filter((s) => s.id === TokenStandards.ERC20);
      }
      return TokenStandardsList.filter((s) => s.id === TokenStandards.TRC20);
    });

    const fromAmount = computed({
      get: () => calculatedFromAmount.value,
      set: (v) => {
        calculatedFromAmount.value = (v === null || Number.isNaN(v) || +v === 0) ? null : (new Decimal(v)).toFixed();
        if ((v === null || +v === 0) && rate.value) {
          calculatedToAmount.value = null;
          return;
        }
        if (rate.value === 0) {
          calculatedToAmount.value = null;
          return;
        }
        const multipliedAmount = new Decimal(v!).mul(new Decimal(rate.value).toFixed(6));
        const isValidAmount = multipliedAmount.comparedTo(new Decimal(0.01));
        calculatedToAmount.value = isValidAmount >= 0 ? formatDecimal(multipliedAmount.toNumber(), 2) : null;
      },
    });
    const toAmount = computed({
      get: () => calculatedToAmount.value,
      set: (v) => {
        calculatedToAmount.value = (v === null || Number.isNaN(v) || +v === 0) ? null : (new Decimal(v)).toFixed();
        if ((v === null || +v === 0) && rate.value) {
          calculatedFromAmount.value = null;
          return;
        }
        if (rate.value === 0) {
          calculatedFromAmount.value = null;
          return;
        }
        const dividedAmount = new Decimal(v!).div(new Decimal(rate.value).toFixed(6));
        const isValidAmount = dividedAmount.comparedTo(new Decimal(0.00000001));
        calculatedFromAmount.value = isValidAmount >= 0 ? formatDecimal(dividedAmount.toNumber(), decimalPlaces.value) : null;
      },
    });
    const subscription = ref<Subscription | null>(null);
    formGroup.statusChanges.subscribe((x) => {
      isValid.value = (x === 'VALID' && notification.getSocketState() === HubConnectionState.Connected);
    });
    const tokenStandard = ref(machineContext.value.fromCurrency === Currency.ETH ? TokenStandards.ERC20 : TokenStandards.TRC20);

    provide('formGroup', formGroup);
    async function submit() {
      const request: CreateDepositRequest = {
        rate: rate.value,
        fromAmount: new Decimal(fromAmount.value!).toFixed(),
        toAmount: new Decimal(toAmount.value!).toFixed(),
        tokenStandard: tokenStandard.value,
      };
      from(TransactionHandler.createDepositAsync(request)).subscribe({
        next: (result: CreateDepositResponse) => {
          result.fromAmount = new Decimal(result.fromAmount).toFixed();
          result.toAmount = new Decimal(result.toAmount).toFixed();
          machine!.value.send('Next', { payload: result});
        },
        error: (statusCode: string) => machine!.value.send('Error', { errorCode: statusCode }),
      });
    }

    function getLimitByTokenStandard() {
      if (!tokenStandard.value || !limitData.value) {
        return;
      }
      let minLimit = limitData.value[TokenStandards[tokenStandard.value]].min;
      let maxLimit = limitData.value[TokenStandards[tokenStandard.value]].max;
      switch (machineContext.value.toCurrency) {
        case Currency.CNY:
          formattedMinLimit.value = minLimit < 1000 ? minLimit.toString() : metricNumberFormat(minLimit);
          formattedMaxLimit.value = maxLimit < 1000 ? maxLimit.toString() : metricNumberFormat(maxLimit);
          break;
        case Currency.VND:
          if (machineContext.value.isShowKVND) {
            // VND換算為KVND因為只顯示到小數點後兩位, 故最小值應該要無條件進位
            minLimit = VNDToKVND(minLimit, Decimal.ROUND_UP);
            maxLimit = VNDToKVND(maxLimit);
          }
          formattedMinLimit.value = amountFormat(2)(minLimit);
          formattedMaxLimit.value = amountFormat(2)(maxLimit);
          break;
        default:
          break;
      }
      formGroup.get('toAmount')!.setValidators([Validators.min(minLimit), Validators.max(maxLimit)]);
      formGroup.get('toAmount')!.updateValueAndValidity();
    }

    async function getDepositLimit() {
      if (!fromCurrecnyName.value || !tokenStandard.value) {
        return;
      }
      limitData.value = await transactionHandler.getDepositLimitAsync();
      getLimitByTokenStandard();
    }

    watch(tokenStandard, () => {
      getLimitByTokenStandard();
    });

    subscription.value = notification.start().pipe(
      filter((g) => g),
      concatMap(() => notification.rateEvent()),
    ).subscribe((x) => {
      rate.value = machineContext.value.isShowKVND ? cryptoCurrencyToKVNDRate(x[0].depositRate) : x[0].depositRate;
      fromAmount.value = calculatedFromAmount.value ? calculatedFromAmount.value : null;
      toAmount.value = calculatedToAmount.value ? calculatedToAmount.value : null;
      prompt!.setNewPrompt('', t('Deposit_Prompt_Rate_Change'));
    });

    getDepositLimit();

    return {
      t,
      formattedMinLimit,
      formattedMaxLimit,
      rate,
      notification,
      getNameByCurrency,
      availableTokenStandard,
      machine,
      amountFormat,
      fromAmount,
      toAmount,
      fromCurrecnyName,
      toCurrencyName,
      isShowKVND,
      isValid,
      prompt,
      formGroup,
      submit,
      subscription,
      calculatedFromAmount,
      calculatedToAmount,
      grayTextSwitch,
      tokenStandard,
      fromCurrenyRateIcon,
      fromCurrenyInputIcon,
      toCurrenyInputIcon,
      TokenStandardsList,
      isShowGuideModal,
      guideContent,
      decimalPlaces,
    };
  },
  unmounted() {
    this.subscription!.unsubscribe();
    this.notification.dispose();
  },
  props: {
    value: {
      default: '',
    },
  },
});
